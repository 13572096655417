* {
  margin: 0;
  padding: 0;
  border: none;
  border-radius: none; }

body {
  zoom: 1; }

#root {
  height: 100%; }

html, body {
  min-height: 100%;
  height: 100%;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  color: #5B6675;
  background-repeat: no-repeat; }

input, select, textarea, button, a {
  outline: none;
  font-family: inherit;
  box-sizing: border-box; }

textarea {
  -webkit-overflow-scrolling: touch; }

button {
  padding: 8px 16px;
  cursor: pointer;
  border-radius: 4px;
  background-color: #fff;
  color: #FFF;
  font-size: 16px; }

h1 {
  font-size: 8vw;
  line-height: 120%;
  font-weight: 300;
  margin-bottom: 20px; }

h2 {
  font-size: 3vw;
  line-height: 120%;
  font-weight: 300;
  margin-bottom: 20px;
  -webkit-font-smoothing: antialiased; }
  @media only screen and (max-width: 999px) {
    h2 {
      font-size: 4vw; } }

h3 {
  font-size: 22px;
  line-height: 28px;
  font-weight: 300;
  margin-bottom: 14px;
  margin-top: 32px;
  color: #9F68AA;
  -webkit-font-smoothing: antialiased; }

h4 {
  font-size: 18px;
  line-height: 26px;
  font-weight: 400;
  margin-bottom: 10px;
  -webkit-font-smoothing: antialiased; }

a {
  color: #9F68AA; }
  a img {
    outline-color: none; }
  a:hover {
    text-decoration: none; }

p {
  font-size: 18px;
  line-height: 150%;
  font-weight: 300;
  margin-bottom: 20px; }
  @media only screen and (max-width: 999px) {
    p {
      font-size: 16px; } }
  p b {
    color: #9F68AA; }
  p span.highlight {
    color: #9F68AA; }

ul {
  margin-bottom: 20px;
  padding-left: 20px; }
  ul li {
    font-size: 18px;
    line-height: 150%;
    font-weight: 300;
    margin-bottom: 10px; }

img {
  width: 100%;
  height: 100%;
  margin-bottom: 26px; }

.mainContainer {
  height: 100%;
  width: 60%;
  max-width: 900px;
  margin: 3rem auto;
  padding: 3rem;
  background: #fff; }
  @media only screen and (max-width: 999px) {
    .mainContainer {
      margin: 2rem auto;
      padding: 2rem;
      width: 70%; } }

.first, nav {
  padding-top: 20px;
  margin-top: 20px;
  border-top: 1px solid #9F68AA; }

.project-details p {
  margin-bottom: 8px; }

.gif-container {
  display: flex;
  justify-content: center; }
  .gif-container img {
    width: 80%; }
    @media only screen and (max-width: 999px) {
      .gif-container img {
        width: 100%; } }

.photo-grid .row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px; }
  .photo-grid .row img {
    width: 48%; }
  @media only screen and (max-width: 999px) {
    .photo-grid .row {
      display: block; }
      .photo-grid .row img {
        width: 100%; } }

.single-photos img {
  margin-bottom: 24px;
  width: 80%;
  margin: 0 auto 24px auto;
  display: block; }

@media only screen and (max-width: 999px) {
  .single-photos img {
    width: 100%; } }

footer ul {
  display: flex;
  justify-content: center;
  align-content: center;
  margin-top: 30px; }
  footer ul li {
    display: inline;
    padding: 0 10px; }
    footer ul li a, footer ul li i {
      display: block;
      color: #9F68AA; }
